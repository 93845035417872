.Wrapper {
    flex-grow: 1;
}

.ItemWrapper {
    background: #2a475e;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    margin: 15px 0;
}

.Question {
    margin: 0;
}

.QuestionButton {
    background: none;
    width: 100%;
    padding: 20px;
    text-align: left;
    font-weight: bold;
    color: inherit;
    cursor: pointer;
}

.QuestionButtonActive {
    padding-bottom: 10px;
}

.Answer {
    padding: 0 20px 20px 20px;
    text-align: left;
    cursor: pointer;
}

.ItemWrapperActive {
    background: #e1e5e7;
    color: #1b2838;
}

@media screen and (max-width: 768px) {
    .QuestionButton {
        padding: 15px;
    }
    .Answer {
        padding: 0 15px 15px 15px;
    }
}
