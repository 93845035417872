.TabsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.TabsWrapper::-webkit-scrollbar {
    width: 0;
    height: 10px;
    background: none;
}

.TabsWrapper::-webkit-scrollbar-corner {
    background: none;
}

.TabsWrapper::-webkit-scrollbar {
    height: 0;
    background: transparent;
}


.Button {
     padding: 20px;
     background: none;
     color: #ffffff;
     font-weight: bold;
    border-radius: 5px 0 0 5px;
 }

.Button:not(.Button.ButtonActive):hover {
    background: #253c51;
    cursor: pointer;
}

.ButtonActive {
    background: #e1e5e7;
    color: #1b2838;
}

@media screen and (max-width: 768px) {
    .TabsWrapper {
        flex-direction: row;
        max-width: 100%;
        overflow-x: auto;
    }

    .Button {
        font-size: 12px;
        padding: 12px;
        border-radius: 5px 5px 0 0;
    }
}
