.Wrapper {
    margin-top: 45px;
    padding: 30px 0;
    font-size: 14px;
    color: #fff;
    background: #111923;
}

.Row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.Col {
    width: 33.3333333%;
    padding: 0 15px
}

.Col:last-child {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .Row {
        flex-direction: column;
        align-items: center;
    }
    .Col {
         width: auto;
     }
    .Col:last-child {
        text-align: center;
    }
}
