.Wrapper {
    align-self: flex-end;
    margin-bottom: auto;
    margin-right: 20px;
}

.Date {
    font-size: 14px;
    color: #666666;
}

.Course {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    font-weight: bold;
    color: #111923;
}
