.Wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 70px;
    color: white;
    background: rgb(17, 25, 35);
}

.Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
}

.Logo {
    font-size: 24px;
    line-height: 26px;
    text-align: left;
}

.RightBlock {
    display: flex;
    align-items: center;
}

.RightBlock .Link {
     margin-left: 15px;
}

.Link {
    display: block;
    color: #ffffff;
    text-decoration: none;
}

.ActiveLink {

}

.MobileMenu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    padding: 20px 0;
    background: rgb(17, 25, 35);
}

.MobileMenu .Link {
    padding: 15px;
}

.MobileMenu .Link:hover {
    background-color: #2a475e;
}

.MobileMenuButton {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 2px;
    margin-left: 10px;
}

.MobileMenuButton:hover {
    cursor: pointer;
}

.MobileMenuButton:hover,
.MobileMenuButton:focus,
.MobileMenuButton:active {
    background-color: #385c79;
}

.MobileMenuOpenButton {
    background: center / 20px no-repeat url("assets/menu.png");
}

.MobileMenuCloseButton {
    position: relative;
    background-color: #2a475e;
}

.MobileMenuCloseButton:before,
.MobileMenuCloseButton:after {
     position: absolute;
     display: block;
     content: "";
     top: 50%;
     left: 50%;
     height: 20px;
     width: 1.5px;
     background-color: white;
    border-radius: 1px;
 }

.MobileMenuCloseButton:before {
     transform: translate(-50%,-50%) rotate(45deg);
 }

.MobileMenuCloseButton:after {
     transform: translate(-50%,-50%) rotate(-45deg);
 }

@media screen and (max-width: 400px) {
    .Logo {
        font-size: 18px;
        line-height: 22px;
    }

    .RightBlock .Link {
        font-size: 14px;
    }
}
