.Wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 20px;
    background: #e1e5e7;
    min-width: 200px;
    flex-grow: 1;
    border-radius: 0 5px 5px 0;
}

.YLable {
    text-align: left;
    margin-left: 30px;
    color: #666666;
    font-size: 14px;
}

.ChartWrapper {
    width: 100%;
    margin-bottom: auto;
}

@media screen and (max-width: 768px) {
    .Wrapper {
        padding: 12px 5px;
        border-radius: 0 0 5px 5px;
    }
}
