body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

p {
  margin: 0;
}

ul {
  list-style: none;
}

.hidden-x-scroll {
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hidden-x-scroll::-webkit-scrollbar-corner {
   background: none;
}
.hidden-x-scroll::-webkit-scrollbar-corner {
   background: none;
}
.hidden-x-scroll::-webkit-scrollbar {
   height: 0;
   background: transparent;
}


