.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background: #1b2838;
    margin: 10px auto;
}

.Inner {
    display: flex;
    background: #2a475e;
    padding: 10px;
    width: 100%;
    max-height: 500px;
    border-radius: 5px;
}


@media screen and (max-width: 768px) {
    .Inner {
        display: block;
    }
}
