
.PopupWrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.3);
    backdrop-filter: blur(1px);
}

.PopupInner {
    position: relative;
    text-align: center;
    width: 400px;
    max-width: 100%;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 5px;
}

.ClosePopupButton {
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 3px;
    background-color: #2a475e;
}

.ClosePopupButton:before,
.ClosePopupButton:after {
    position: absolute;
    content: "";
    display: block;
    width: 1.3px;
    height: 13px;
    background-color: white;
    left: 50%;
    top: 50%;
    border-radius: 2px;
}

.ClosePopupButton:before {
    transform: translate(-50%,-50%) rotate(45deg);
}

.ClosePopupButton:after {
    transform: translate(-50%,-50%) rotate(-45deg);
}

.ClosePopupButton:hover {
    background-color: #111923;
    cursor: pointer;
}

.Title {
    margin: 10px auto 20px;
    font-size: 25px;
    font-weight: bold;
}



@media screen and (max-width: 500px) {
    .PopupInner {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }
}
