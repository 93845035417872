.Wrapper {
    /*display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    justify-content: space-between;
    align-content: center;*/
    width: 400px;
    max-width: 100%;
    flex-grow: 1;
}

.Method {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0;
    padding: 20px 20px;
    background: #2a475e;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.Icon {
    height: 40px;
    width: auto;
    margin-bottom: 10px;
}

.QR {
    width: 100%;
}

.Text {
    margin: 10px 0;
    font-size: 14px;
}

.AddressWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    border: 1px solid #000000;
    border-radius: 4px;
}

.CopyNotice {
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 10px 6px;
    font-size: 14px;
    background: #2a475e;
    color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}

.CopyButton {
    position: relative;
    display: flex;
    align-items: center;
    background: none;
    padding: 5px;
}

.CopyIcon {
    width: 15px;
}

.Address {
    padding: 5px;
    display: flex;
    color: #000000;
}

.CopyButton:before {
    position: absolute;
    display: block;
    content: "";
    left: -15px;
    top: 0;
    width: 15px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
}

.LinkWrapper {
    display: flex;
    justify-content: center;
}
