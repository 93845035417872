.Wrapper {
    flex-grow: 1;
    border-radius: 5px;
    background: #e1e5e7;
    color: #1b2838;
    font-size: 14px;
    margin-top: 20px;
    padding: 20px;
    text-align: left;
}

.Head {
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
}

.Link {
    color: black;
}

.ItemTitle {
    margin: 0;
    font-size: 14px;
}

.List li {
    margin: 20px 0;
    text-align: left;
}


@media screen and (max-width: 768px) {
    .Wrapper {
        padding: 15px;
    }
  .Head {
      font-size: 16px;
  }

  .List li {
      margin: 15px 0;
      text-align: left;
  }
}
